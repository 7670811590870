.form {
	width: 100%;
}

.form,
.paper {
	box-sizing: border-box;

	* {
		box-sizing: inherit;
	}

	&.light {
		--dropdownColor: var(--primary1Color);
	}

	&.dark {
		--dropdownColor: var(--primary2Color);
	}
}

.select {
	font-family: var(--bodyFontStack);
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.25px;
	padding: 11px 10px;
	color: var(--dropdownColor);
	background-color: var(--background1Color);
	border: 1px solid var(--dropdownColor);
	border-radius: 2px;
	height: 40px;

	&:focus {
		background-color: var(--background1Color);
	}

	&.borderless {
		border-color: var(--background1Color);
	}
}

.icon {
	margin-right: 10px;
	top: initial;

	path {
		stroke: var(--dropdownColor);
	}
}

.disabled {
	opacity: 0.5;
}

.item {
	color: var(--primary2Color);
	border-top: 1px solid var(--dropdownColor);
	padding: 10px 10px;
	font-family: var(--bodyFontStack);
	font-size: 12px;
	letter-spacing: 0.4px;
	background-color: var(--background1Color);

	&:hover {
		color: var(--background1Color);
		background-color: var(--primary1Color) !important;
	}

	&:focus {
		background-color: var(--background1Color);
	}

	&:first-child {
		border-top: none;
	}
}

.placeholderItem {
	color: var(--dropdownColor);
	background-color: var(--background1Color);
	padding: 12px 10px;
	font-size: 14px;
	letter-spacing: 0.25px;
	font-weight: 500;
	height: 40px;
	line-height: 16px;
	pointer-events: none;

}

.item, .placeholderItem {
	&.selectedItem {
		background-color: var(--background1Color);
	}
}

.list {
	padding: 0;
}

.paper.bordered {
	border: 1px solid var(--dropdownColor);
	border-radius: 2px;
	box-shadow: none;
}

.paper.borderless {
	border: none;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
