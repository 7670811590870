.main {
	height: 100%;
	font-family: var(--bodyFontStack);
	color: var(--primary2Color);
	font-size: 14px;
}

.group:not(:first-child) {
	margin-top: 10px;
	border-top: 1px solid rgba(231, 231, 231, 0.7); // --grey4Color but with opacity
}

.groupTitle {
	font-weight: bold;
	letter-spacing: 0.1px;
	text-align: center;
	display: block;
	line-height: 40px;
}

.linkContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;

	.link {
		display: block;
		flex: 1 1 auto;
		color: var(--primary2Color);
		font-family: var(--bodyFontStack);
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 0.25px;
		text-align: left;
		line-height: 40px;
		border: none;
		background: none;
		appearance: none;
		cursor: pointer;
		padding: 0;

		&:hover,
		&.active {
			color: var(--primary1Color);
		}
	}

	.status {
		margin-top: 20px;
		flex: 0 0 10px;
	}
}

.dropdownItem {
	width: 100%;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
