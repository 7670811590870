@import '../styleMixins';

.content{
	position: relative;
}

.contentCenter{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 75%;
    height: 60%;
    margin: auto;
}

.contentCenterImage{
	display: block;
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.message{
	text-align: center;
	color: var(--primary2Color);
}

.message div{
	line-height: 23px;
}

.messageAlignment {
	position: absolute;
	margin: 0 0 0 40%;
}

.primary {
	font-family: Ubuntu;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	text-align: center;
	letter-spacing: 0.15px;
}

.secondary {
	font-family: Ubuntu;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	text-align: center;
	letter-spacing: 0.15px;
}


.sizeStorybook{
	width: 1000px;
	height: 580px;
}
