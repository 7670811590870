.box {
	position: relative;
	z-index: 0;
	max-width: 500px;

	&:not(.disabled):hover .summary {
		opacity: 1;
		transform: translateY(0);
		pointer-events: all;
	}

	&:not(.disabled):hover .button {		
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	}
}

.button {
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);
}

.summary {
	transform: translateY(15px);
	opacity: 0;
	pointer-events: none;
	transition: all 300ms ease;
}

.selector {
	position: absolute;
	top: 52px;
	left: 0;
	right: 0;
	background-color: var(--background1Color);
	font-size: 10px;
	border-radius: 2px 2px 0 0;
	padding: 10px;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	transition: box-shadow 300ms ease;

	&:hover {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)
	}

	&.open {
		display: block;
	}

	&.closed {
		display: none;
	}

	:global(.MuiCheckbox-root) {
		padding-left: 0;
		padding-top: 2px;
		vertical-align: baseline;
	}

	:global(.MuiIconButton-colorSecondary):hover,
	:global(.MuiCheckbox-colorSecondary.Mui-checked):hover {
		background-color: transparent;
	}
}

.items,
.controls {
	display: grid;
	grid-template-columns: 1fr 1fr;

	> span {
		display: flex;
		margin-bottom: 4px;
	}

	label {
		margin-top: 4px;
		cursor: pointer;
	}
}
