@import '../styleMixins';

.content{
	position: relative;
}

.contentCenter{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 60%;
    height: 60%;
    margin: auto;
}

.contentCenterImage{
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	width: 90%;
	height: 90%;
}

.messageAlignment{
	text-align: center;
	margin-bottom: 50px;
}

.message {
	color: var(--primary2Color);
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.25px;
}


.sizeStorybook{
	width: 800px;
	height: 620px;
}
