@mixin invisibleScrollbar {
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin customScrollbar {
	scrollbar-color: var(--primary1Color) transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--primary1Color);
		border-radius: 6px;
	}
}
