.uploadFile {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.uploadFileInput {
	z-index: -1;
	position: absolute;
	color: transparent;
	width: 94px;
	opacity: 0;
}