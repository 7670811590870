@import '../styleMixins';

.box {
	height: 100%;
	width: 80px;
	background-color: var(--background1Color);
	overflow: auto;

	@include invisibleScrollbar;

	&:before {
		content: "";
		display: block;
		height: 160px;
		background-position: center 26px;
		background-image: url('../icons/adia-logo.svg');
		background-repeat: no-repeat;
	}
}
