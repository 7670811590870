.root {
	margin: 0 -11px 14px;
	display: flex;
	width: auto;
}

.inputRoot {
	border-radius: 2px;
	font-size: 14px;
	background-color: var(--background2TransparentColor);
	border: 1px solid var(--background2Color);
	color: var(--primary2Color);

	.input {
		padding: 29px 10px 6px;
		height: 16px;
		letter-spacing: 0.25px;
	}

	&.focused {
		background-color: var(--background2TransparentColor);
	}

	&.adornedEnd {
		padding-right: 0;
	}

	&.error:not(.focused) {
		border-color: var(--accent4Color);
		color: var(--accent4Color);
	}
}

.label {
	color: var(--primary2Color);
	opacity: 0.5;
	font-size: 14px;
	letter-spacing: 0.25px;

	&.focused {
		color: var(--primary2Color);
	}

	&.filled.shrink {
		transform: translate(11px, 11px) scale(0.86);
		letter-spacing: 0.47px;
	}

	&.error {
		color: inherit;
	}
}

.formHelper {
	font-size: 10px;
	margin-left: 10px;
	margin-right: 10px;
}

.calendarRoot {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
