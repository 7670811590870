.box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 300px;
	padding-top: 15px;

	span,
	button {
		display: inline-block;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		font-size: 12px;
		border-radius: 2px;
		flex: 0 0 20px;
		position: relative;
		border: none;
		appearance: none;
		background: none;
		cursor: default;
	}

	button {
		cursor: pointer;
		padding: 0;

		&:disabled {
			cursor: default;
			opacity: 0.5;
		}

		&:hover:not(:disabled) {
			color: var(--primary1Color);

			path {
				stroke: var(--primary1Color);
			}
		}
	}

	.selected {
		background-color: var(--primary2Color);
		color: var(--background1Color);
		cursor: default;

		&:disabled {
			opacity: 1;
		}
	}

	&.compact {
		width: 90px;

		button {
			width: 40px;
			height: 40px;
			flex-basis: 40px;
		}
	}
}

.status {
	position: absolute;
	left: 6px;
	top: -15px;
}
