.labelsPart {
	margin-left: 4px;
}

.label {
	font-size: 10px;
	font-weight: 500;
}

.email {
	font-size: 10px;
	font-weight: normal;
}
