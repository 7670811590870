.button {
	height: 40px;
	border: 1px solid;
	border-radius: 2px;
	padding: 0 15px;
	font-family: var(--bodyFontStack);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 0.25px;
	cursor: pointer;
	display: flex;
	align-items: center;
	white-space: nowrap;

	&:focus {
		outline: none;
	}

	&:hover:enabled {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	}

	&:disabled {
		opacity: 0.3;
		cursor: initial;
	}
}

.icon {
	margin-right: 5px;

	> * {
		transform: translateY(1px);
	}
}

.content {
	display: inline-block;
	vertical-align: middle;
}

.filled.primary {
	color: var(--background1Color);
	background: var(--primary2Color);
	border-color: var(--primary2Color);

	&:hover:enabled {
		color: var(--background1Color);
		background: var(--primary1Color);
		border-color: var(--primary1Color);
		box-shadow: 0px 5px 15px var(--primary1Color);
	}

	svg * {
		stroke: var(--background1Color);
	}
}

.filled.secondary {
	color: var(--background1Color);
	background: var(--primary1Color);
	border-color: var(--primary1Color);

	&:hover:enabled{
		color: var(--background1Color);
		background: var(--primary2Color);
		border-color: var(--primary2Color);
		box-shadow: 0px 5px 15px var(--primary2Color);
	}

	svg * {
		stroke: var(--background1Color);
	}
}

.lined.secondary {
	color: var(--primary1Color);
	background: var(--background1Color);
	border-color: var(--primary1Color);

	&:hover:enabled{
		color: var(--background1Color);
		background: var(--primary1Color);
		box-shadow: 0px 5px 15px var(--primary1Color);

		svg * {
			stroke: var(--background1Color);
		}
	}

	svg * {
		stroke: var(--primary1Color);
	}
}

.lined.primary {
	color: var(--primary2Color);
	background: var(--background1Color);
	border-color: var(--primary2Color);

	&:hover:enabled{
		color: var(--background1Color);
		background: var(--primary2Color);
		box-shadow: 0px 5px 15px var(--primary2Color);

		svg * {
			stroke: var(--background1Color);
		}
	}

	svg * {
		stroke: var(--primary2Color);
	}
}
