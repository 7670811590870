.box {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	z-index: 1000;
	background-color: var(--background2Color);
	overflow: hidden;
	overflow-x: auto;
}
