.box {
	background: var(--primary1Color);
	color: var(--background1Color);
	font-size: 10px;
	position: absolute;
	bottom: 40px;
	left: 0;
	right: 0;
	z-index: -1;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	padding: 10px;
	border-radius: 2px 2px 0 0;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)
}

.controls {
	margin-left: 10px;
	display: flex;
	align-items: flex-start;

	> span {
		cursor: pointer;
		display: flex;
		align-items: center;

		> svg {
			width: 15px;
			height: 15px;
			vertical-align: middle;
		}
	}
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	position: relative;

	&:not(:last-child)::after {
		position: absolute;
		bottom: -5px;
		left: 0;
		content: '';
		width: 100%;
		height: 1px;
		background-color: var(--background1Color);
	}
}

.singleRow {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	position: relative;

	&:not(:last-child)::after {
		position: absolute;
		bottom: -5px;
		left: 0;
		content: '';
		width: 100%;
		height: 1px;
		background-color: var(--background1Color);
	}
}

.options {
	display: flex;
	flex-direction: column;
	width: 100%;
	row-gap: 1px;
}
