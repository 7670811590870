.paper {
  margin: 0px;
  padding: 0px;
}

.list {
  margin: 0px;
  padding: 0px;
}




