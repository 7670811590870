@keyframes fadeToggle {
	from {
		opacity: 1;
	}

	to {
		opacity: 0.1;
	}
}

.loading {
	text-align: center;
	animation: fadeToggle 1s ease-in-out infinite alternate;
	margin-top: 15vh;
}
