.container {
	position: relative;
	height: 100%;
	background-color: var(--background2Color);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contentWrapper {
	transition: width 500ms ease;
	direction: rtl;
	height: 100vh;
	overflow: hidden auto;
}

.content {
	display: inline-block;
	direction: ltr;
}
