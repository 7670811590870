.link {
	padding-top: 56px;
	display: block;
	background-repeat: no-repeat;
	background-position: center 10px;
	text-align: center;
	font-size: 10px;
	height: 80px;
	width: 80px;
	text-decoration: none;
	color: var(--primary2Color);

	&:global(.active) {
		color: var(--primary1Color);
		text-decoration: none;
		font-weight: bold;
	}

	&:hover {
		text-decoration: none;
		font-weight: bold;
	}
}
