.link {
	text-decoration: underline;
}

.withIcon:before {
	content: url('../icons/call_lined.svg');
	width: 25px;
	height: 25px;
	margin-right: 4px;
	vertical-align: middle;
}
