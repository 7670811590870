.button {
	position: absolute;
	right: 0;
	top: 35px;
	transform: rotateZ(-90deg);
	transform-origin: bottom right;
	background: transparent;
	border: none;
	height: 30px;
	white-space: nowrap;
	cursor: pointer;
	font-size: 12px;
	color: var(--primary2Color);

	&:hover {
		color: var(--primary1Color);
	}

	svg {
		vertical-align: middle;
	}
}
