.root {
  margin: 0px;
  outline: none;
  border-bottom: 1px solid var(--primary1Color);
}

.root:last-child {
  margin: 0px;
  border-bottom: none;
}

.root:hover {
  background-color: var(--primary1Color);
}

.selected {
  color:  var(--background1Color);
  background-color: var(--primary1Color);
}
