:root {
	--primary1Color: #7ede82;
	--primary2Color: #003c3a;
	--primary2TransparentColor: rgba(0, 60, 58, 0.15);
	--primary1TransparentColor: rgba(126, 222, 130, 0.3);
	--secondaryColor: #ffb843;
	--background1Color: #ffffff;
	--background2Color: #f0f1f4;
	--background2TransparentColor: rgba(240, 241, 244, 0.5);
	--background3Color: #d8f5da;
	--grey1Color: #171717;
	--grey2Color: #5c5c5c;
	--grey3Color: #a2a2a2;
	--grey4Color: #e7e7e7;
	--accent1Color: #3cdbd3;
	--accent2Color: #0d3b66;
	--accent3Color: #fe7337;
	--accent4Color: #ef3054;
	--overlayColor:  rgba(0, 60, 58, 0.1);

	@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&subset=latin-ext');
	--bodyFontStack: Roboto, sans-serif;

	@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700&display=swap&subset=latin-ext');
	--headerFontStack: Ubuntu, sans-serif;
}

