.box {
	background: var(--background1Color);
	padding: 0 20px 0 40px;
	line-height: 80px;
	display: grid;
	grid-template-columns: auto auto;

	h1 {
		color: var(--primary1Color);
		font-size: 1.25rem;
		font-weight: bold;
		margin: 0;

		&::selection {
			background: var(--primary2Color);
		}
	}

  	.titleBarControls {
		display:flex;
	  	margin: auto 20px auto auto;
	}
}
