.dropdownAreaButton {
	display: grid;
	position: relative;
	grid-template-rows: auto auto;
	grid-template-columns: auto;
	overflow-wrap: break-word;
}

.container {
	position: absolute;
	z-index: 10;
	padding: 10px;
	box-sizing: border-box;
	background-color: var(--background1Color);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contentOnTop {
	bottom: 40px;
}

.contentOnBottom {
	top: 40px;
}

.closeIcon {
	display: flex;
	position: relative;
	justify-content: flex-end;
	height: 18px;

	:global(svg) {
		position: absolute;
		cursor: pointer;
		top: -6px;
		right: -6px;
	}
}

.button {
	display: block;
	border-radius: 2px;
	height: 40px;

	&:hover:not(:disabled) {
		background-color: var(--primary2Color) !important;
		box-shadow: 0px 5px 15px var(--primary2Color) !important;
		border: 0;
		text-decoration: none;
	}
}

.focus {
	color: var(--background1Color) !important;
	background-color: var(--primary1Color) !important;
	
	svg * {
		stroke: var(--background1Color) !important;
	}
}

.open {
	display: block;
}

.closed {
	display: none;
}

.storybookDisplay {
	position: fixed;
	bottom: 0;
}
