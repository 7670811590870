@import '../styleMixins';

.box {
	background-color: var(--background1Color);
	border-radius: 5px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	margin: 25px;
	flex: 1 0 auto;
	transition: all 300ms ease;
	position: relative;
}

.size-half {
	@media screen and (max-width: 1200px) {
		width: calc(100% - 50px);
		max-width: calc(100% - 50px);
	}

	width: calc(50% - 50px);
	max-width: calc(50% - 50px);
}

.size-full {
	width: calc(100% - 50px);
	max-width: calc(100% - 50px);
}

.maximized {
	position: fixed;
	z-index: 200;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 10px;

	.content {
		max-height: calc(100vh - 70px);
		overflow: auto;
	}
}

.header {
	font-size: 20px;
	font-weight: 500;
	font-family: var(--headerFontStack);
	align-items: center;
	height: 60px;
	padding: 0 20px;
	border-bottom: 1px solid rgba(126, 222, 130, 0.7);
	display: flex;
	justify-content: stretch;
}

.icon {
	flex: 0 0 20px;
	margin-right: 20px;
	transform: translateY(2px);
}

.title {
	flex: 1 0 auto;
}

.toggleButton {
	flex: 0 0 50px;
	background: transparent;
	border: none;
	margin-right: -20px;
	color: var(--primary1Color);
	cursor: pointer;
	transition: transform 200ms ease;
	transform-origin: 50% 39%;

	&:hover {
		color: var(--primary2Color);
		transform: scale(1.25);
	}
}

.content {
	font-size: 14px;
	overflow: hidden;
	@include customScrollbar;
}

.overlay {
	background: linear-gradient(0deg, var(--overlayColor), var(--overlayColor));
	z-index: 1;
	position: absolute;
	top:0;
	left:0;
	height: 100%;
	width: 100%;
}

.actionsContainer {
	flex: 0 0 auto;
	display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
