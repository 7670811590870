.buttons {
	button {
		height: 25px;
		min-width: 60px;
		padding: 0 10px;
		border: 1px solid;
		font-family: var(--bodyFontStack);
		font-size: 12px;
		font-style: normal;
		font-weight: normal;
		letter-spacing: 0.2px;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		color: var(--primary2Color);
		background: var(--background1Color);
		border-color: var(--primary2Color);
		border-radius: 0;

		&:focus {
			outline: none;
		}

		&:hover:not(:disabled) {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			color: var(--background1Color);
			background: var(--primary2Color);
			border-color: var(--primary2Color);
			box-shadow: 0px 5px 15px var(--primary2Color);
		}

		&:disabled {
			opacity: 0.5;
			cursor: default;
		}
	}

	:first-child {
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
	}

	:last-child {
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
	}

	:not(:first-child) {
		margin-left: -1px;
	}
}
