.labelRoot{
    margin: 0 80px 0 0;
}

.label{
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: var(--primary2Color);
    margin-left: 13px;
}

.radioRoot{
    padding: 0px;
    color: var(--primary2Color);
    &:hover{
        background-color: initial;
    }
}

.checked{
    color: var(--primary1Color);
}

.disabled{
    opacity: 0.5;
    color: var(--primary2Color) !important;
}