.status-red {
	stroke: var(--accent4Color);
	fill: transparent;
}

.status-amber {
	stroke: var(--secondaryColor);
	fill: transparent;
}

.status-green {
	stroke: var(--primary1Color);
	fill: var(--primary1Color);
}
