@import '../styleMixins';

.content {
	background-color: var(--background2Color);
}

.scrollContent {
	height: 90%;
	font-size: 12px;
	line-height: 14px;
	margin: 6px;
	overflow: scroll;
	overflow-y: auto;
	overflow-x: hidden;
	@include customScrollbar;
}

.message {
	position: relative;
	height: 10%;
	font-size: 10px;
	line-height: 12px;
	color: var(--primary2Color);
	margin-left: 6px;
	margin-bottom: 6px;
}

.message p {
	position: absolute;
	bottom: 4px;
}

.noteSizeStoryBook {
	width: 210px;
	height: 260px;
}
