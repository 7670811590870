.tabSelector {
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: space-evenly;
}

.button {
  justify-content: center;
  height: 50px;
  border: 1px solid;
  border-radius: 0px;
  padding: 0 15px;
  font-family: var(--bodyFontStack);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;

  &:focus {
	outline: none;
  }

  &:hover:enabled {
	color: var(--primary2Color);
	background: var(--primary1Color);
	border-color: var(--primary1Color);
  }

  &:disabled {
	opacity: 0.3;
	cursor: initial;
  }

  color: var(--background1Color);
  background: var(--primary2Color);
  border-color: var(--primary2Color);
}

.selected {
  position: relative;
  color: var(--primary2Color);
  background: var(--primary1Color);
  border-color: var(--primary1Color);

  &:after, &:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
  }

  &:after {
	border-top-color: var(--primary1Color);
	border-width: 8px;
	margin-left: -8px;
  }

  &:before {
	border-top-color: var(--primary1Color);
	border-width: 8px;
	margin-left: -8px;
  }
}





