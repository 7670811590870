.root{
	border-bottom: 1px solid var(--primary1Color);
	max-width: 500px;

	::before {
		content: none;
		transform: none;
		transition: none;
	}

	::after {
		border-bottom: none;
	}
}

.input {
	font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: var(--primary2Color);
}

.autocompleteInput {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: var(--primary2Color);
}

.label {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.2px;
	color: var(--grey3Color);

	&.focus {
		color: var(--grey3Color);
	}

	&.shrink {
		transform: none;
	}
}

.autocompleteLabel {
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 0.2px;
	color: var(--primary1Color);

	&.focus {
		color: var(--primary1Color);
	}

	&.shrink {
		transform: none;
	}
}

.listbox {
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;	
	letter-spacing: 0.2px;
	color: var(--primary2Color);
	background-color: var(--primary1TransparentColor);
	padding: 0;	
}

.listbox::-webkit-scrollbar {
	display: none;
}

.paper {
	border: none;
	border-radius: 0;
	background-color: var(--background1Color);
	box-shadow: 0;
	margin: 0;
}

.option {
	padding-left: 10px;
	padding-bottom: 4px;
	padding-top: 4px;
}