@import '../styleMixins.scss';

.box {
	position: relative;
}

.selector {
	position: absolute;
	bottom: 0;
	background-color: var(--background1Color);
	z-index: 2;
}

.topBar {
	text-align: right;
	padding: 6px;
	height: 20px;
	box-sizing: content-box;
}

.closeIcon {
	cursor: pointer;
}

.searchBox {
	padding: 0 10px 10px;

	input {
		box-sizing: border-box;
		padding: 10px;
		border: 0;
		outline: 0;
		border-radius: 2px;
		height: 40px;
		width: 100%;
		background-color: var(--background2Color);
		color: var(--primary2Color);
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.4px;
		font-family: Roboto;

		&::placeholder {
			opacity: 0.5;
		}
	}
}

.confirmButtonContainer {
	padding-top: 5px;
}

.options {
	max-height: 120px;
	overflow: scroll;
	@include invisibleScrollbar;
}

.option {
	box-sizing: border-box;
	height: 40px;
	line-height: 30px;
	padding: 5px 10px;
	color: var(--primary2Color);
	font-size: 12px;
	letter-spacing: 0.4px;
	font-family: Roboto;
	cursor: pointer;

	&:last-child {
		margin-bottom: 5px;
	}
}

.optionSelected {
	color: var(--background1Color);
	background-color: var(--primary1Color);
}

.storybookDisplay {
	.storybookSelectedOptionsList {
		min-height: 300px;
	}

	.storybookButton {
		width: 230px;
		display: block;
	}
}