.open{
	display: block;
}

.closed {
	display: none;
}

.button {
	display: block;
	margin: 15px 0 0 0;
	width: 154px;
	border-radius: 2px;
	height: 40px;
}

.textInput {
	position: relative;
	margin-left: 0;
	label {
		position: absolute;
		top: 15px;
		left: 10px;
		color: var(--primary2Color);
		opacity: 0.5;
	}

	.labelFocus {
		visibility: hidden;
	}

	.labelBlur {
		visibility: visible;
	}

	.labelBlurCursor {
		visibility: visible;
		cursor: text;
	}

	input {
		padding: 10px;
		border: 0;
		outline: 0;
		border-radius: 2px;
		height: 40px;
		width: 134px;
		background-color: rgba(0, 60, 58, 0.05);
	}
}

.storybookDisplay {
  position: fixed;
  bottom: 0;
}

.closeIcon {
	cursor: pointer;
	position: absolute;
	right: 6px;
	top: 6px;
}

.items,
.controls {
	> span {
		display: flex;
	}

	label {		
    display: flex;
    align-items: center;
		cursor: pointer;
		padding-bottom: 2px;
	}
}

.selector {
	position: absolute;
	bottom: 40px;
	background-color: var(--background1Color);
	font-size: 10px;
	border-radius: 2px 2px 0 0;
	padding: 26px 10px 10px 10px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

	:global(.MuiCheckbox-root) {
		padding: 7px 10px 7px 0;
		vertical-align: baseline;
	}

	:global(.MuiIconButton-colorSecondary):hover,
	:global(.MuiCheckbox-colorSecondary.Mui-checked):hover {
		background-color: transparent;
	}
}

.box {
	position: relative;
	z-index: 2;
	max-width: 500px;

	&:not(.disabled):hover .summary {
		opacity: 1;
		transform: translateY(0);
		pointer-events: all;
	}
}
