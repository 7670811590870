.inputTextFilter input[type='text']::placeholder {
	font-family: inherit;
}

.bordered {
	border: 1px solid var(--primary2Color);

	&:hover {
		border: 1px solid var(--primary1Color);
	}
}

.borderedHighlighted {
	border: 1px solid var(--primary1Color);
}

.inputTextFilter {
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	background-color: var(--background1Color);
	font-size: 0.75rem;
	line-height: 0.875rem;
	box-shadow: none;
	outline: none;
	font-family: var(--bodyFontStack);
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
	height: 40px;
	color: var(--primary2Color);
	max-width: 500px;

	&:hover {
		outline: none;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	}
}

.inputTextFilterActive {
	outline: none;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

// Focus out state

.inputFilterFocusOut {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

.inputFilterFocusOutTitle {
	box-sizing: border-box;
	padding-left: 10px;
}


// Focus in state

.inputFilterFocusIn {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 2px;
	width: 100%;
	height: 100%;
}

.inputFilterFocusInTitle {
	height: 100%;
	color: var(--primary1Color);
	font-size: 10px;
	font-weight: 500;
	line-height: 12px;
	padding-left: 10px;
	margin-top: 5px;
}

.inputFilter {
	box-sizing: border-box;
	z-index: 10;
	border: none;
	font-weight: 500;
	letter-spacing: 0.1px;
	font-family: var(--bodyFontStack);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	border-radius: 2px;
	width: 100%;
	height: 100%;
	background-color: var(--background1Color);
	padding-left: 10px;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: var(--grey4Color);
	}
}

.horizontalLineWrapper {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 10px;
}

.horizontalLineWrapperHidden {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	visibility: hidden;
}

.horizontalLine {
	border: none;
	background-color: var(--primary1Color);
	height: 1px;
	width: 100%;
	margin: 0;
}


.autoSuggestContainer {
	width: 100%;
	height: 100%;
}

.inputFilterSuggestionsWrapper {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 10px;
	animation: opacityIn 0.5s;
}

.inputFilterSuggestions {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	list-style-type: none;
	text-decoration: none;
	font-weight: normal;
	font-size: 10px;
	letter-spacing: 0.2px;
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: var(--background3Color);
	animation: opacityIn;
}

.inputFilterFirstSuggestion {
	border-top: solid 1px var(--primary1Color);
}

.inputFilterSuggestion {
	box-sizing: border-box;
	padding: 0;
	display: flex;
	align-content: flex-start;
	padding-left: 10px;
	width: 100%;
	height: 100%;
}

.appliedFilterContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 2px;
	width: 100%;
	height: 100%;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	animation: opacityIn 0.5s;
}

.appliedFilterValue {
	width: 100%;
	height: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	color: var(--primary2Color);
}

.closeIcon {
	visibility: hidden;
	position: absolute;
	right: 10px;
	top: 14px;
	width: 10px;
	height: 10px;
	display: flex;
	justify-content: flex-end;
	color: var(--primary1Color);

	svg {
		font-size: 0.7rem;
		position: absolute;
	}
}

.inputFilterFocusIn:hover > .closeIcon, .closeIcon:hover {
	visibility: visible;
	cursor: pointer;
}


@keyframes opacityIn {
	100% {
		opacity: 1;
	}

	0% {
		opacity: 0;
	}
}
