.box {
	display: flex;
	justify-content: space-between;
	border-radius: 2px;
	background-color: var(--background1Color);
	color: var(--primary2Color);
	font-size: 14px;
	line-height: 18px;
	font-family: var(--bodyFontStack);
	font-style: normal;
	font-weight: 500;
	box-shadow: none;
	border: none;
	padding: 12px 10px;
	width: 100%;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	transition: box-shadow 300ms ease;

	&.disabled {
		cursor: default;
	}

	svg {
		align-self: center;
	}
}

.title {
	flex: 1 1 auto;
	cursor: inherit;
	white-space: nowrap;

	&:not(.disabled):hover {		
		color: var(--primary1Color);
	}
}

.filterApplied {
	color: var(--primary1Color);
}

.open {
	color: var(--primary1Color);
}

.badge {
	color: var(--background1Color);
	background: var(--primary1Color);
	display: inline-block;
	width: 18px;
	text-align: center;
	border-radius: 9px;
	font-size: 10px;
	vertical-align: middle;
	flex: 0 0 18px;
	margin: 0 5px;
	align-self: center;
}

.disabled {
	.title,
	svg,
	.badge {
		opacity: 0.5;
	}
}
