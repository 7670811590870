.form {
	width: 100%;
}

.form,
.paper {
	box-sizing: border-box;

	* {
		box-sizing: inherit;
	}

	&.light {
		--dropdownColor: var(--primary1Color);
	}

	&.dark {
		--dropdownColor: var(--primary2Color);
	}
}

.title {
	display: flex;
	font-family: var(--bodyFontStack);
	color: var(--dropdownColor);
	padding: 12px 10px 20px 10px;
	font-size: 10px;
	letter-spacing: 0.25px;
	font-weight: 500;
	height: 15px;
	line-height: 12px;
	pointer-events: none;
}

.suggestion {
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	padding: 3px;
	cursor: pointer;
}

.suggestionsList {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	list-style-type: none;
	text-decoration: none;
	font-weight: normal;
	font-size: 10px;
	letter-spacing: 0.2px;
	display: flex;
	flex-direction: column;
	width: 100%;
	animation: opacityIn;
}

.suggestionsContainer {
	position: absolute;
	z-index: 1;
	padding: 0 5px;
	width: 100%;
	background-color: var(--background1Color);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
}

.inputFilter {
	box-sizing: border-box;
	z-index: 10;
	border: none;
	padding: 0 5px;
	font-weight: 500;
	letter-spacing: 0.1px;
	font-family: var(--bodyFontStack);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.4px;
	border-radius: 2px;
	width: 100%;
	height: 100%;
	background-color: var(--background1Color);

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: var(--grey4Color);
	}
}

.horizontalLine {
	width: 100%;
	background-color: var(--dropdownColor);
	border: 1px solid var(--dropdownColor);
	margin: 0;
}

.select {
	display: flex;
	align-items: center;
	font-family: var(--bodyFontStack);
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.25px;
	padding: 12px 10px;
	color: var(--dropdownColor);
	background-color: var(--background1Color);
	border: 1px solid var(--dropdownColor);
	border-radius: 2px;
	height: 50px;
	cursor: pointer;

	&:focus {
		background-color: var(--background1Color);
	}
}

.optionsBox {
	background-color: var(--background1Color);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
}

.arrow {
	margin-left: auto;
	align-self: center;
	path {
		stroke: var(--dropdownColor);
	}
}

.rotate {
	transform: rotate(180deg);
}

