.textField {
  background-color: white;
  height: 40px;
  padding: 10px;
}

.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button {
  height: 40px;
  margin-left: 10px;

  &:before {
	content: "";
	display: block;
	height: 160px;
	background-position: center 26px;
	background-image: url('../icons/search-small_lined.svg');
	background-repeat: no-repeat;
  }
}

