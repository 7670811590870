.box {
	font-family: var(--bodyFontStack);
	color: var(--primary2Color);
}

.label {
	font-size: 12px;
	padding: 0;
	color: var(--grey3Color);
	letter-spacing: 0.4px;
	display: block;
}

.value {
	padding: 5px 0 8px 0;
	display: block;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.2;
	letter-spacing: 0.25px;
}
